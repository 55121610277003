var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validateAndSave()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"px-5"},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Edit' : 'Add')+" Promotion ")]),_c('v-card-text',{staticClass:"pa-5 pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Name","rules":[function (v) { return !!v || 'Name is required'; }]},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Code","counter":12,"rules":[
              function (v) { return !!v || 'Code is required'; },
              function (v) { return (v && v.length <= 12) ||
                'Code must be less than 12 characters'; },
              function (v) { return (v && v.indexOf(' ') <= 0) || 'Code must not contain spaces'; } ]},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","transition":"scroll-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","outlined":"","readonly":"","append-icon":"mdi-calendar","label":"Start date","rules":[function (v) { return !!v || 'Start date is required'; }]},model:{value:(_vm.model.startDate),callback:function ($$v) {_vm.$set(_vm.model, "startDate", $$v)},expression:"model.startDate"}},on))]}}]),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.model.endDate},on:{"input":function($event){_vm.startDatePicker = false}},model:{value:(_vm.model.startDate),callback:function ($$v) {_vm.$set(_vm.model, "startDate", $$v)},expression:"model.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","transition":"scroll-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","outlined":"","readonly":"","append-icon":"mdi-calendar","label":"End date","rules":[function (v) { return !!v || 'End date is required'; }]},model:{value:(_vm.model.endDate),callback:function ($$v) {_vm.$set(_vm.model, "endDate", $$v)},expression:"model.endDate"}},on))]}}]),model:{value:(_vm.endDatePicker),callback:function ($$v) {_vm.endDatePicker=$$v},expression:"endDatePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.model.startDate},on:{"input":function($event){_vm.endDatePicker = false}},model:{value:(_vm.model.endDate),callback:function ($$v) {_vm.$set(_vm.model, "endDate", $$v)},expression:"model.endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mx-3",attrs:{"outlined":"","text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }