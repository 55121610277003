<template>
  <v-card outlined>
    <v-form v-model="valid" ref="form" @submit.prevent="validateAndSave()">
      <v-card-title class="px-5">
        {{ isEditing ? 'Edit' : 'Add' }} Promotion
      </v-card-title>
      <v-card-text class="pa-5 pt-2">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.name"
              dense
              outlined
              label="Name"
              :rules="[(v) => !!v || 'Name is required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.code"
              dense
              outlined
              label="Code"
              :counter="12"
              :rules="[
                (v) => !!v || 'Code is required',
                (v) =>
                  (v && v.length <= 12) ||
                  'Code must be less than 12 characters',
                (v) =>
                  (v && v.indexOf(' ') <= 0) || 'Code must not contain spaces',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="startDatePicker"
              offset-y
              min-width="auto"
              transition="scroll-y-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="model.startDate"
                  v-on="on"
                  dense
                  outlined
                  readonly
                  append-icon="mdi-calendar"
                  label="Start date"
                  :rules="[(v) => !!v || 'Start date is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="model.startDate"
                no-title
                scrollable
                :max="model.endDate"
                @input="startDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="endDatePicker"
              offset-y
              min-width="auto"
              transition="scroll-y-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="model.endDate"
                  v-on="on"
                  dense
                  outlined
                  readonly
                  append-icon="mdi-calendar"
                  label="End date"
                  :rules="[(v) => !!v || 'End date is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="model.endDate"
                no-title
                scrollable
                :min="model.startDate"
                @input="endDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn depressed color="primary" type="submit" :loading="loading">
              Save
            </v-btn>
            <v-btn
              outlined
              text
              class="mx-3"
              :disabled="loading"
              @click="close()"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import moment from 'moment';
import BaseForm from '../../../helpers/base-form';

export default {
  extends: BaseForm,

  props: {
    url: { default: 'promotions', required: false },
  },

  data: () => ({
    startDatePicker: false,
    endDatePicker: false,
  }),

  created() {
    if (this.model && this.model.startDate) {
      this.model.startDate = moment(this.model.startDate).format('YYYY-MM-DD');
      this.model.endDate = moment(this.model.endDate).format('YYYY-MM-DD');
    }
  },

  methods: {
    validateAndSave() {
      if (!this.$refs.form.validate()) return;

      const successMessage = this.model.id
        ? 'Promotion updated.'
        : 'New promotion added.';

      this.save(successMessage);
    },
  },
};
</script>

<style scoped>
.v-input.v-input--dense.v-text-field.v-text-field--enclosed.v-text-field--outlined {
  height: 45px !important;
}
</style>
